const fontFamily = 'Comcast New Vision';

const ReportStyles = {
  page: {
    backgroundColor: '#fff'
  },
  report: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    position: 'relative',
    height: '100%',
    paddingLeft: 9
  },
  photoHeaderCaption: {
    fontFamily,
    fontSize: 9,
    fontWeight: 300,
    backgroundColor: '#F8F8F8',
    padding: '8 24'
  },
  leftBorder: {
    position: 'absolute',
    top: 0,
    left: 0,
    height: '11in'
  },
  border: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: 8,
    height: 800
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    flexGrow: 0,
    justifyContent: 'space-between',
    height: 105,
    padding: '15 30 15 21'
  },
  headerContent: {
    flexGrow: 1
  },
  headerTitle: {
    fontFamily,
    fontSize: 12,
    margin: 0,
    lineHeight: 1,
    textTransform: 'uppercase',
    letterSpacing: '2.5px',
    fontWeight: 600
  },
  headerSubtitle: {
    fontFamily,
    fontSize: 48,
    fontWeight: 400,
    margin: 0
  },
  headerImage: {
    width: 84,
    flexShrink: 0,
    marginTop: 4
  },
  sectionWrapper: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 2,
    justifyContent: 'space-between'
  },
  section: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 2,
    justifyContent: 'flex-start'
  },
  investmentWrapper: {
    position: 'relative',
    flexGrow: 0,
    flexShrink: 0,
    margin: '0 19 auto 21',
    padding: '15 30 2 0',
    textAlign: 'left'
  },
  investmentFrame: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: 555,
    height: 100
  },
  investmentText: {
    fontFamily,
    fontSize: 17,
    fontWeight: 400,
    lineHeight: 1.4,
    margin: 0
  },
  investmentFigure: {
    color: '#006CB5',
    fontFamily,
    fontSize: 17,
    fontWeight: 700,
    margin: 0
  },
  stats: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    flexShrink: 1,
    flexGrow: 0,
    margin: 'auto 30 auto 21',
    lineHeight: 1.1
  },
  statsBlock: {
    width: '33.33%',
    paddingRight: 23,
    marginBottom: 19
  },
  statsTitle: {
    color: '#006CB5',
    fontFamily,
    fontSize: 17,
    marginBottom: 5,
    fontWeight: 700
  },
  statsBody: {
    fontFamily,
    fontSize: 10,
    fontWeight: 500
  },
  statsFootnote: {
    fontFamily,
    fontSize: 6,
    fontWeight: 300,
    paddingTop: 8
  },
  featured: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    marginVertical: 'auto',
    padding: '0 30 0 21'
  },
  captionWrapper: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    flexGrow: 0,
    justifyContent: 'center',
    backgroundColor: '#F8F8F8',
    width: 270,
    padding: '15 33',
    marginRight: 12
  },
  captionHeading: {
    fontFamily,
    fontSize: 9,
    fontWeight: 700,
    letterSpacing: '3px',
    textTransform: 'uppercase',
    marginBottom: '3%'
  },
  legendWrapper: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    width: 225
  },
  legend: {
    display: 'inline-flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    alignItems: 'center',
    marginRight: 12,
    maxWidth: 195,
    marginBottom: 8
  },
  legendKey: {
    minWidth: 12,
    width: 12,
    height: 12,
    marginRight: 8
  },
  legendText: {
    display: 'block',
    fontFamily,
    maxWidth: 185,
    fontSize: 9,
    fontWeight: 300,
    margin: 0,
    lineHeight: 1.1
  },
  caption: {
    display: 'block',
    fontFamily,
    width: 200,
    fontSize: 9,
    fontWeight: 300,
    margin: 0,
    lineHeight: 1.1
  },
  learnMore: {
    paddingTop: '2%',
    color: '#000000',
    fontWeight: '800',
    textDecoration: 'underline'
  },
  author: {
    fontFamily,
    fontSize: 10,
    fontWeight: 700,
    marginTop: 10,
    marginBottom: 0
  },
  authorCompany: {
    fontFamily,
    fontSize: 10,
    fontWeight: 300,
    marginBottom: 0
  },
  featuredImageWrapper: {
    width: 300,
    flexShrink: 0
  },
  featuredImage: {
    width: '100%'
  },
  footer: {
    margin: '30 30 10 21',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'space-between'
  },
  moreInfoWrapper: {
    flex: '1 1 50%',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap'
  },
  qrCode: {
    width: '30',
    height: '30',
    marginRight: '6'
  },
  moreInfoText: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  moreInfo: {
    color: '#000000',
    fontFamily,
    fontSize: 7,
    fontWeight: 700,
    letterSpacing: 2,
    textTransform: 'uppercase',
    textDecoration: 'none'
  },
  moreInfoUrl: {
    color: '#000000',
    textDecoration: 'underline',
    fontFamily,
    fontSize: 7,
    fontWeight: 700,
    letterSpacing: 2,
    textTransform: 'uppercase'
  },
  copyrightWrapper: {
    flex: '0 1 33%',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column'
  },
  copyright: {
    fontFamily,
    fontSize: 4
  }
};

export const CityReportStyles = {
  report: {
    ...ReportStyles.report,
    paddingLeft: 0
  },
  statsBlockFourUp: {
    width: '50%',
    maxWidth: '100%'
  },
  statsBlockThreeUp: {
    fontSize: 11
  },
  cityOverviewText: {
    fontSize: 7,
    fontFamily,
    fontWeight: 300,
    marginBottom: 6
  },
  cityOverviewFigure: {
    color: '#006CB5',
    fontWeight: 400
  },
  qrCode: {
    width: '38px',
    height: '38px',
    marginRight: '12px'
  },
  copyrightWrapper: {
    ...ReportStyles.copyrightWrapper,
    flex: '0 1 75%'
  }
};

export const MapStyles = {
  featured: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    marginVertical: 'auto',
    height: 134,
    padding: '0 30 0 21',
    backgroundColor: '#F8F8F8'
  },
  captionWrapper: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    alignItems: 'flex-start',
    width: 240,
    maxWidth: 400,
    height: '100%',
    marginRight: 15,
    padding: '15 0'
  },
  captionWrapperInner: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    justifyContent: 'center',
    height: '100%',
    width: 225
  },
  featuredImageWrapper: {
    position: 'absolute',
    top: -22,
    right: 30,
    width: 340,
    height: 200,
    flexShrink: 0,
    display: 'flex'
  },
  featuredImage: {
    width: '100%',
    objectFit: 'contain'
  }
};

export const QuoteStyles = {
  captionWrapper: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    flexGrow: 0,
    justifyContent: 'center',
    width: 360,
    height: 159,
    padding: '15 33',
    backgroundColor: '#F8F8F8'
  },
  featuredImageWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 196,
    height: '100%',
    flexGrow: 0,
    flexShrink: 0,
    paddingLeft: 24,
    backgroundColor: '#F8F8F8'
  },
  featuredImage: {
    height: 78
  }
};

export default ReportStyles;

const Sprite = () => {
  return (
    <svg
      width="0"
      height="0"
      xmlns="http://www.w3.org/2000/svg"
      style={{ position: 'absolute' }}
    >
      <symbol viewBox="0 0 24 24" id="icon-add">
        <path d="M22.286 9.429H14.57V1.714C14.571.768 13.804 0 12.857 0h-1.714c-.947 0-1.714.768-1.714 1.714V9.43H1.714C.768 9.429 0 10.196 0 11.143v1.714c0 .947.768 1.714 1.714 1.714H9.43v7.715c0 .946.767 1.714 1.714 1.714h1.714c.947 0 1.714-.768 1.714-1.714V14.57h7.715c.946 0 1.714-.767 1.714-1.714v-1.714c0-.947-.768-1.714-1.714-1.714z" />
      </symbol>
      <symbol viewBox="0 0 24 24" id="icon-alert">
        <path d="M15.75 20.25A3.754 3.754 0 0112 24a3.754 3.754 0 01-3.75-3.75A3.754 3.754 0 0112 16.5a3.754 3.754 0 013.75 3.75zM8.684 1.181l.638 12.75c.03.599.524 1.069 1.123 1.069h3.11c.6 0 1.093-.47 1.123-1.069l.638-12.75A1.125 1.125 0 0014.192 0H9.808c-.644 0-1.156.539-1.124 1.181z" />
      </symbol>
      <symbol viewBox="0 0 24 24" id="icon-check">
        <path d="M8.151 20.55l-7.8-7.8a1.2 1.2 0 010-1.697l1.697-1.697a1.2 1.2 0 011.698 0L9 14.61 20.254 3.356a1.2 1.2 0 011.698 0l1.697 1.697a1.2 1.2 0 010 1.697l-13.8 13.8a1.2 1.2 0 01-1.698 0z" />
      </symbol>
      <symbol viewBox="0 0 24 24" id="icon-chevron-down">
        <path d="M11.067 19.102l-10.68-11a1.387 1.387 0 010-1.921l1.245-1.283a1.292 1.292 0 011.863-.002L12 13.613l8.505-8.717a1.292 1.292 0 011.863.002l1.246 1.283c.515.53.515 1.39 0 1.921l-10.681 11c-.515.53-1.35.53-1.866 0z" />
      </symbol>
      <symbol viewBox="0 0 24 24" id="icon-chevron-left">
        <path d="M4.898 11.067l11-10.68a1.387 1.387 0 011.921 0l1.283 1.245c.53.515.53 1.348.002 1.863L10.386 12l8.718 8.505a1.292 1.292 0 01-.002 1.863l-1.284 1.246c-.53.515-1.39.515-1.92 0l-11-10.682a1.292 1.292 0 010-1.865z" />
      </symbol>
      <symbol viewBox="0 0 24 24" id="icon-chevron-right">
        <path d="M19.102 12.933l-11 10.68c-.53.516-1.39.516-1.921 0l-1.283-1.245a1.292 1.292 0 01-.002-1.863L13.613 12 4.896 3.495a1.292 1.292 0 01.002-1.863L6.18.386a1.387 1.387 0 011.921 0l11 10.681c.53.515.53 1.35 0 1.866z" />
      </symbol>
      <symbol viewBox="0 0 24 24" id="icon-chevron-up">
        <path d="M12.933 4.898l10.68 11c.516.53.516 1.39 0 1.921l-1.245 1.283c-.514.53-1.348.53-1.863.002L12 10.387l-8.505 8.717a1.292 1.292 0 01-1.863-.002L.386 17.82a1.387 1.387 0 010-1.921l10.681-11c.515-.53 1.35-.53 1.866 0z" />
      </symbol>
      <symbol viewBox="0 0 24 24" id="icon-close">
        <path d="M16.55 12l6.822-6.823a2.145 2.145 0 000-3.033L21.856.628a2.145 2.145 0 00-3.033 0L12 7.451 5.177.628a2.145 2.145 0 00-3.033 0L.628 2.144a2.145 2.145 0 000 3.033L7.451 12 .628 18.823a2.145 2.145 0 000 3.033l1.516 1.516a2.145 2.145 0 003.033 0L12 16.549l6.823 6.823a2.145 2.145 0 003.033 0l1.516-1.516a2.145 2.145 0 000-3.033L16.549 12z" />
      </symbol>
      <symbol fill="none" viewBox="0 0 24 24" id="icon-edit">
        <g clipPath="url(#iclip0)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M20.49.39l3.12 3.12c.52.52.52 1.36 0 1.88l-2.44 2.439-4.999-5 2.44-2.44c.52-.519 1.36-.519 1.88 0zM0 23.333v-4.052c0-.187.067-.347.2-.48L14.745 4.256l4.999 5L5.186 23.8a.625.625 0 01-.467.2H.667A.66.66 0 010 23.333z"
            fill="#006CB5"
          />
        </g>
        <defs>
          <clipPath id="iclip0">
            <path fill="#fff" d="M0 0h24v24H0z" />
          </clipPath>
        </defs>
      </symbol>
      <symbol fill="none" viewBox="0 0 24 24" id="icon-error">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M24 12c0 6.629-5.373 12-12 12S0 18.629 0 12C0 5.375 5.373 0 12 0s12 5.375 12 12zm-12 2.42a2.226 2.226 0 100 4.451 2.226 2.226 0 000-4.452zM9.887 6.418l.359 6.58a.58.58 0 00.58.55h2.348a.58.58 0 00.58-.55l.36-6.58a.58.58 0 00-.58-.613h-3.068a.58.58 0 00-.58.613z"
          fill="#B42846"
        />
      </symbol>
      <symbol viewBox="0 0 12 12" id="icon-external">
        <path d="M10.667 10.667H1.333V1.333H6V0H1.333C.593 0 0 .6 0 1.333v9.334C0 11.4.593 12 1.333 12h9.334C11.4 12 12 11.4 12 10.667V6h-1.333v4.667zM7.333 0v1.333h2.394L3.173 7.887l.94.94 6.554-6.554v2.394H12V0H7.333z" />
      </symbol>
      <symbol viewBox="0 0 24 24" id="icon-file">
        <path d="M13.5 6.375V0H4.125C3.502 0 3 .502 3 1.125v21.75C3 23.498 3.502 24 4.125 24h15.75c.623 0 1.125-.502 1.125-1.125V7.5h-6.375A1.128 1.128 0 0113.5 6.375zm3 11.063c0 .309-.253.562-.563.562H8.064a.564.564 0 01-.563-.563v-.375c0-.309.253-.562.563-.562h7.874c.31 0 .563.253.563.563v.375zm0-3c0 .309-.253.562-.563.562H8.064a.564.564 0 01-.563-.563v-.374c0-.31.253-.563.563-.563h7.874c.31 0 .563.253.563.563v.374zm0-3.376v.376c0 .309-.253.562-.563.562H8.064a.564.564 0 01-.563-.563v-.374c0-.31.253-.563.563-.563h7.874c.31 0 .563.253.563.563zM21 5.715V6h-6V0h.286c.3 0 .586.117.797.328l4.589 4.594c.21.21.328.497.328.792z" />
      </symbol>
      <symbol viewBox="0 0 24 24" id="icon-minus">
        <path d="M22.286 9.5H1.714C.768 9.5 0 10.246 0 11.167v1.666c0 .92.768 1.667 1.714 1.667h20.572c.946 0 1.714-.746 1.714-1.667v-1.666c0-.92-.768-1.667-1.714-1.667z" />
      </symbol>
      <symbol viewBox="0 0 24 24" id="icon-search">
        <path d="M23.626 20.711l-4.665-4.664a1.122 1.122 0 00-.795-.328h-.762a9.683 9.683 0 002.058-5.988A9.73 9.73 0 009.731 0 9.73 9.73 0 000 9.731a9.73 9.73 0 009.731 9.731c2.26 0 4.337-.767 5.988-2.058v.762c0 .3.117.585.328.795l4.664 4.665c.44.44 1.151.44 1.586 0l1.324-1.324c.44-.44.44-1.151.005-1.59zM9.73 15.72a5.985 5.985 0 01-5.988-5.988A5.985 5.985 0 019.73 3.743a5.985 5.985 0 015.988 5.988 5.985 5.985 0 01-5.988 5.988z" />
      </symbol>
      <symbol fill="none" viewBox="0 0 24 24" id="icon-success">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M24 12c0 6.627-5.373 12-12 12S0 18.627 0 12 5.373 0 12 0s12 5.373 12 12zm-13.388 6.354l8.903-8.903a.774.774 0 000-1.095L18.42 7.26a.774.774 0 00-1.095 0l-7.26 7.26-3.39-3.39a.774.774 0 00-1.095 0l-1.095 1.096a.774.774 0 000 1.095l5.032 5.032a.774.774 0 001.095 0z"
          fill="#0DB14B"
        />
      </symbol>
      <symbol viewBox="0 0 24 24" id="icon-trash">
        <path d="M3 21.75A2.25 2.25 0 005.25 24h13.5A2.25 2.25 0 0021 21.75V6H3v15.75zm12.75-12a.75.75 0 111.5 0v10.5a.75.75 0 11-1.5 0V9.75zm-4.5 0a.75.75 0 111.5 0v10.5a.75.75 0 11-1.5 0V9.75zm-4.5 0a.75.75 0 011.5 0v10.5a.75.75 0 11-1.5 0V9.75zm15-8.25h-5.625l-.44-.877A1.125 1.125 0 0014.676 0H9.319a1.112 1.112 0 00-1.003.623l-.441.877H2.25a.75.75 0 00-.75.75v1.5c0 .414.336.75.75.75h19.5a.75.75 0 00.75-.75v-1.5a.75.75 0 00-.75-.75z" />
      </symbol>
      <symbol fill="none" viewBox="0 0 24 22" id="icon-warning">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M23.73 18.547c.769 1.313-.196 2.953-1.733 2.953H2.002c-1.539 0-2.5-1.643-1.732-2.953l9.998-17.063c.77-1.313 2.696-1.31 3.464 0l9.998 17.063zM12 15.02c-1.059 0-1.917.844-1.917 1.886 0 1.042.858 1.887 1.917 1.887 1.059 0 1.917-.845 1.917-1.887S13.059 15.02 12 15.02zm-1.82-6.782l.31 5.578a.497.497 0 00.499.465h2.022a.497.497 0 00.5-.465l.309-5.578a.496.496 0 00-.5-.52h-2.64a.496.496 0 00-.5.52z"
          fill="#F37021"
        />
      </symbol>
    </svg>
  );
};

export default Sprite;

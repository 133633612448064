/* eslint camelcase: ["error", {allow: ["first_displayed_option", "selected_option"]}]*/
import { createContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { MAX_DATA_POINTS, CITY_ACCEPTABLE_DATA_POINTS } from '../../constants';
import {
  getInitialOptions,
  getInitialStats,
  getInitialGroup,
  getSelectedStats,
  reorderStats
} from '../../helpers';
import { useFetch } from '../browser';
import { gtag } from 'ga-gtag';

export const StatesContext = createContext();

export const useSelectedState = () => {
  const history = useHistory();
  const { state } = history.location;

  return state;
};

export const useStates = () => {
  const [statesEndpoint, setStatesEndpoint] = useState();
  const [states] = useFetch(statesEndpoint, []);
  const history = useHistory();

  const handleSubmit = event => {
    event.preventDefault();

    const select = event.target.elements.state;
    const abbr = select.value;
    const name = select.options[select.selectedIndex].text;
    gtag('event', 'select_state', {
      selectedState: name,
      selectedStateAbbr: abbr
    });

    history.push({
      pathname: '/report',
      state: {
        abbr,
        name
      }
    });
  };

  useEffect(() => {
    setStatesEndpoint({ url: '/api/content/states' });
  }, []);

  return [states, handleSubmit];
};

export const useCities = () => {
  const [citiesEndpoint, setCitiesEndpoint] = useState();
  const [cities] = useFetch(citiesEndpoint, []);
  const history = useHistory();

  const handleSubmit = event => {
    event.preventDefault();
    const select = event.target.elements.city;
    const abbr = select.options[select.selectedIndex].dataset.stateAbbrev;
    const city = select.value;

    history.push({
      pathname: '/report',
      state: {
        city,
        abbr
      }
    });
  };

  useEffect(() => {
    setCitiesEndpoint({ url: `/api/content/cities` });
  }, []);

  return [cities, handleSubmit];
};

export const useReportData = () => {
  const selectedState = useSelectedState();
  const [statsEndpoint, setStatsEndpoint] = useState();
  const [stateEndpoint, setStateEndpoint] = useState();
  const [statsResponse] = useFetch(statsEndpoint, []);
  const [stateResponse] = useFetch(stateEndpoint, null);
  const [stats, setStats] = useState([]);
  const [reportData, setReportData] = useState({});
  const selectedCity = selectedState?.city;

  useEffect(() => {
    if (!selectedState.abbr) {
      return;
    }

    if (stats.length) {
      setStats([]);
    }

    setStatsEndpoint({
      url: selectedCity
        ? `/api/content/stats/${selectedState.abbr}/${selectedCity}`
        : `/api/content/stats/${selectedState.abbr}`
    });

    setStateEndpoint({
      url: selectedCity
        ? `/api/content/states/${selectedState.abbr}/${selectedCity}`
        : `/api/content/states/${selectedState.abbr}`
    });
  }, [selectedState.abbr]);

  useEffect(() => {
    if (statsResponse) {
      setStats(statsResponse);
    }
  }, [statsResponse]);

  useEffect(() => {
    if (stateResponse) {
      setReportData(stateResponse);
    }
  }, [stateResponse]);

  return [reportData, stats, selectedState];
};

export const useReport = () => {
  const state = useSelectedState();
  const report = state && state.report;

  return report;
};

export const useSelectedFeatureId = () => {
  const report = useReport();

  return report && report.featureId;
};

export const useSelectedPhotoHeaderId = () => {
  const report = useReport();

  return report && report.photoHeaderId;
};

export const useReportPreview = ({ report, stats, photoheader }) => {
  const [reportEndpoint, setReportEndpoint] = useState();
  const [savedReport] = useFetch(reportEndpoint, null);
  const history = useHistory();
  const selectedState = useSelectedState();
  const editedReport = useReport();

  const handleSubmit = event => {
    gtag('event', 'generate_report', {
      first_displayed_option: report.options[0].value,
      selected_option: report.featureType
    });
    const form = event.target;
    const { value } = form.elements.nickname;
    const title = (value && value.trim()) || report.title;
    const id = editedReport && editedReport.id;

    event.preventDefault();

    const bodyData = {
      title,
      stats,
      featureId: report.featureId,
      featureType: report.featureType,
      photoHeaderId: photoheader?.photoHeaderId
    };

    if (id) {
      const params = {
        url: `/api/report/${id}`,
        method: 'PUT',
        body: JSON.stringify({
          ...bodyData,
          id,
          state: report.stateAbbr,
          cityName: report?.cityName
        })
      };

      // Update existing report
      setReportEndpoint(params);
      return;
    }

    const url = selectedState?.city
      ? `/api/reports/${selectedState.abbr}/${selectedState.city}`
      : `/api/reports/${selectedState.abbr}`;
    const params = {
      url,
      method: 'POST',
      body: JSON.stringify({
        ...bodyData
      })
    };

    // Create new report
    setReportEndpoint(params);
  };

  useEffect(() => {
    if (savedReport) {
      history.push(`/report/${savedReport.id}`);
    }
  }, [savedReport]);

  return handleSubmit;
};

export const useReportBuilder = reportContent => {
  const [selectedContent, setSelectedContent] = useState(null);
  const [selectedStats, setSelectedStats] = useState([]);
  const [selectedPhotoHeaderContent, setSelectedPhotoHeaderContent] =
    useState(null);
  const [report, setReport] = useState(null);
  const [showModal, setModal] = useState(false);
  const [error, setError] = useState('');
  const [confirmed, setConfirmed] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [showSpinner, setSpinner] = useState(true);
  const generateReport = useReportPreview({
    report,
    stats: selectedStats,
    photoheader: selectedPhotoHeaderContent
  });
  const history = useHistory();
  const savedReport = useReport();

  const confirmContent = ({ featuredContent, featureType, saved }) => {
    const statCount = selectedStats.length;

    if (saved) {
      if (!confirmed) {
        setConfirmed(true);
      }

      setSelectedContent({
        ...featuredContent,
        featureType
      });
      return;
    }

    if (reportContent?.cityName && !selectedPhotoHeaderContent) {
      setError('Please confirm photo header');
      return false;
    }

    if (!statCount) {
      setError('Please select and confirm data points');
      return false;
    }

    if (!reportContent?.cityName && statCount < MAX_DATA_POINTS) {
      setError(
        `Select ${MAX_DATA_POINTS - statCount} more data point${
          statCount < 5 ? 's' : ''
        }`
      );
      return false;
    }

    if (statCount > MAX_DATA_POINTS) {
      setError(`Select only ${MAX_DATA_POINTS} data points`);
      return false;
    }

    // Clear errors and set content
    setConfirmed(true);
    setError(null);
    setExpanded(false);
    setSelectedContent({
      ...featuredContent,
      featureType
    });
  };

  const confirmStats = (stats, saved) => {
    if (saved) {
      setSelectedStats(stats);
      return;
    }

    setSelectedStats(stats);
    setExpanded(true);
    setError('');

    if (!confirmed) {
      // Scroll to next section
      window.scrollTo({
        top: 400,
        behavior: 'smooth'
      });
    }
  };

  const confirmPhotoHeaderSelection = ({ photoHeaderContent, saved }) => {
    if (saved) {
      setSelectedPhotoHeaderContent(photoHeaderContent);
      return;
    }

    setSelectedPhotoHeaderContent(photoHeaderContent);
    setExpanded(true);
    setError('');

    if (!confirmed) {
      // Scroll to next section
      window.scrollTo({
        top: 400,
        behavior: 'smooth'
      });
    }
  };

  const handleStateChange = event => {
    const form = event.target;
    const select = form.elements.state;
    const abbr = select.value;
    const name = select.options[select.selectedIndex].text;

    event.preventDefault();
    gtag('event', 'change_state', {
      selectedState: name,
      selectedStateAbbr: abbr
    });

    setConfirmed(false);
    setError('');
    setSelectedContent(null);
    setSelectedStats([]);
    setModal(false);

    history.push({
      pathname: '/report',
      state: {
        abbr,
        name,
        report: savedReport
      }
    });
  };

  const handleCityChange = event => {
    const form = event.target;
    const select = form.elements.city;
    const abbr = select.options[select.selectedIndex].dataset.stateAbbrev;
    const city = select.value;

    event.preventDefault();

    setConfirmed(false);
    setError('');
    setSelectedContent(null);
    setSelectedStats([]);
    setModal(false);

    history.push({
      pathname: '/report',
      state: {
        abbr,
        city,
        report: savedReport
      }
    });
  };

  useEffect(() => {
    if (reportContent && selectedContent) {
      setReport({
        ...reportContent,
        ...selectedContent,
        featuredCaption: selectedContent.caption
      });
    } else {
      setReport(null);
    }
  }, [reportContent, selectedContent]);

  useEffect(() => {
    if (!savedReport) {
      setSpinner(false);
    }

    if (confirmed) {
      setSpinner(false);
    }
  }, [savedReport, confirmed]);

  useEffect(() => {
    if (!reportContent?.cityName) {
      setSelectedPhotoHeaderContent(null);
    }
  }, [reportContent]);

  const data = {
    error,
    expanded,
    report,
    savedReport,
    showModal,
    showSpinner,
    stats: selectedStats,
    photoheader: selectedPhotoHeaderContent
  };

  const actions = {
    changeState: handleStateChange,
    changeCity: handleCityChange,
    confirmContent,
    confirmStats,
    confirmPhotoHeaderSelection,
    showModal: () => {
      setError('');
      setModal(true);
    },
    hideModal: () => setModal(false),
    submit: generateReport,
    toggle: () => setExpanded(!expanded)
  };

  return [data, actions];
};

export const useSelectedStats = (stats = [], onConfirm) => {
  const [allStats, setAllStats] = useState(stats);
  const [groupedStats, setGroupedStats] = useState(stats);
  const [selectedStats, setSelectedStats] = useState([]);
  const [confirmed, setConfirmed] = useState(false);
  const [expanded, setExpanded] = useState(true);
  const report = useReport();

  const handleSelect = event => {
    const { value } = event.target;
    const selectedId = Number(value);
    const order = selectedStats.length ? selectedStats.length + 1 : 1;

    if (selectedStats.includes(selectedId)) {
      const updatedStats = reorderStats(allStats, selectedId);

      setAllStats(updatedStats);
    } else if (selectedStats.length < MAX_DATA_POINTS) {
      const statAdded = allStats.map(stat => {
        if (stat.id !== selectedId) {
          return stat;
        }
        return { ...stat, order };
      });

      setAllStats(statAdded);
    }
  };

  const handleToggle = () => {
    setExpanded(!expanded);
  };

  const handleConfirm = (event, saved) => {
    setConfirmed(true);
    setExpanded(false);

    if (onConfirm) {
      const updatedStats = getSelectedStats(allStats);

      onConfirm(updatedStats, saved);
    }
  };

  useEffect(() => {
    if (stats.length) {
      const initialStats = getInitialStats(stats, report);

      setAllStats(initialStats);
    }
  }, [stats, report]);

  useEffect(() => {
    if (allStats.length) {
      // Group stats by category
      const grouped = allStats.reduce((group, stat) => {
        const match = group.find(category => category.name === stat.category);

        if (match) {
          match.stats.push(stat);
        } else {
          //CEXP-2362 Hardcode subheading for Community Impact | Digital Opportunity
          const subheading =
            'Select data points are unavailable at this time, including total nonprofit giving, number of nonprofit partners supported, Lift Zones, and Comcast RISE. For questions about Impact & Inclusion data, please email StateReports@Comcast.com, and questions will be forwarded to the HQ Impact & Inclusion team.';
          if (stat.category === 'Community Impact | Digital Opportunity') {
            group.push({
              name: stat.category,
              stats: [stat],
              subheading: subheading
            });
          } else {
            group.push({ name: stat.category, stats: [stat] });
          }
        }
        return group;
      }, []);

      setGroupedStats(grouped);

      const selected = allStats.filter(stat => stat.order).map(stat => stat.id);

      setSelectedStats(selected);
    }
  }, [allStats]);

  useEffect(() => {
    const maxStatLimit = report?.cityName
      ? CITY_ACCEPTABLE_DATA_POINTS.includes(selectedStats.length)
      : selectedStats.length === MAX_DATA_POINTS;

    if (!confirmed && report && maxStatLimit) {
      // Confirm with saved parameter set to true
      handleConfirm(null, true);
    }
  }, [report, selectedStats]);

  const data = {
    confirmed,
    expanded,
    categories: groupedStats,
    selected: selectedStats
  };

  const actions = {
    confirm: handleConfirm,
    selectStat: handleSelect,
    toggle: handleToggle
  };

  return [data, actions];
};

export const useGroupedOptions = (group, selectedId) => {
  const [currentOptions, setOptions] = useState(null);
  const [saved, setSaved] = useState(false);

  const handleChange = selected => {
    const updatedOptions = currentOptions.map(option => {
      return option.id === selected.id
        ? { ...option, checked: true }
        : { ...option, checked: false };
    });

    setOptions(updatedOptions);
  };

  useEffect(() => {
    if (!group) {
      return;
    }

    const { options } = group;

    if (options && options.length) {
      const initialOptions = getInitialOptions(options, selectedId, saved);

      setOptions(initialOptions);

      if (!saved && selectedId) {
        setSaved(true);
      }
    }
  }, [group, selectedId]);

  return [currentOptions, handleChange];
};

export const useFeaturedOption = (group, options) => {
  const [featuredContent, setFeatured] = useState({});

  useEffect(() => {
    if (options && options.length) {
      const checked = options.find(option => option.checked);

      let selected;

      if (checked) {
        selected = group.options.find(option => option.id === checked.id);
      }

      if (!selected) {
        // Exit early without selection
        return;
      }

      if (group.value !== 'quote') {
        setFeatured({
          featureId: selected.featureId,
          caption: selected.caption,
          captionHeading: selected.captionHeading,
          legend: selected.legend,
          legendColors: selected.legendColors,
          image: selected.image.url,
          hyperLink: selected.hyperlink
        });
      } else {
        setFeatured({
          featureId: selected.featureId,
          author: selected.author,
          authorCompany: selected.authorCompany,
          caption: selected.quote
        });
      }
    }
  }, [options]);

  return featuredContent;
};

export const usePhotoHeaderOption = (group, options) => {
  const [photoHeaderContent, setPhotoHeaderContent] = useState({});

  useEffect(() => {
    if (options && options.length) {
      const checked = options.find(option => option.checked);

      let selected;

      if (checked) {
        selected = group.options.find(option => option.id === checked.id);
      }

      if (!selected) {
        // Exit early without selection
        return;
      }

      setPhotoHeaderContent({
        photoHeaderId: selected.photoHeaderId,
        image: selected.image.url,
        caption: selected.caption
      });
    }
  }, [options]);

  return photoHeaderContent;
};

export const useSelectedPhotoHeader = (content, confirmContent) => {
  const [selectedGroup, setSelectedGroup] = useState();
  const [confirmed, setConfirmed] = useState(false);
  const [expanded, setExpanded] = useState(true);
  const selectedPhotoHeaderId = useSelectedPhotoHeaderId();
  const [currentOptions, handleChange] = useGroupedOptions(
    selectedGroup,
    selectedPhotoHeaderId
  );

  const photoHeaderContent = usePhotoHeaderOption(
    selectedGroup,
    currentOptions
  );
  const options = content.photoHeaderOptions;
  const report = useReport();
  const [saved, setSaved] = useState(false);
  const label = selectedGroup ? selectedGroup.label : '';

  const handleToggle = () => {
    setExpanded(!expanded);
  };

  const handleSelect = event => {
    const { value } = event.target;
    const nextOption = options.find(option => option.value === value);

    if (nextOption) {
      setSelectedGroup(nextOption);
    }
  };

  const handleConfirm = () => {
    setExpanded(false);
    if (confirmContent) {
      confirmContent({ photoHeaderContent });
      setConfirmed(true);
    }
  };

  useEffect(() => {
    if (options && options.length) {
      const initialGroup = getInitialGroup(options, report, saved);

      setSelectedGroup(initialGroup);

      if (report && !saved) {
        setSaved(true);
      }
    }
  }, [options, report]);

  useEffect(() => {
    if (saved && report && confirmContent && !confirmed) {
      if (photoHeaderContent.photoHeaderId) {
        // Confirm content for saved report
        confirmContent({ photoHeaderContent, saved: true });
        setExpanded(false);
        setConfirmed(true);
      }
    }
  }, [saved, report, confirmed, photoHeaderContent.photoHeaderId]);

  const data = {
    confirmed,
    options: currentOptions,
    label,
    expanded
  };
  const actions = {
    handleChange,
    handleSelect,
    handleConfirm,
    toggle: handleToggle
  };

  return [data, photoHeaderContent, actions];
};

export const useSelectedGroup = (content, confirmContent) => {
  const [selectedGroup, setSelectedGroup] = useState();
  const [confirmed, setConfirmed] = useState(false);
  const [saved, setSaved] = useState(false);
  const selectedFeatureId = useSelectedFeatureId();
  const [currentOptions, handleChange] = useGroupedOptions(
    selectedGroup,
    selectedFeatureId
  );
  const featuredContent = useFeaturedOption(selectedGroup, currentOptions);
  const report = useReport();
  const label = selectedGroup ? selectedGroup.label : 'No selection';
  const featureType = selectedGroup && selectedGroup.value;
  const options = content.options;
  const handleSelect = event => {
    const { value } = event.target;
    const nextOption = options.find(option => option.value === value);

    if (nextOption) {
      setSelectedGroup(nextOption);
    }
  };
  const handleConfirm = () => {
    if (confirmContent) {
      confirmContent({ featuredContent, featureType });
      setConfirmed(true);
    }
  };

  useEffect(() => {
    if (options && options.length) {
      const initialGroup = getInitialGroup(options, report, saved);

      setSelectedGroup(initialGroup);

      if (report && !saved) {
        setSaved(true);
      }
    }
  }, [options, report]);

  useEffect(() => {
    if (saved && report && confirmContent && !confirmed) {
      if (featuredContent.featureId) {
        // Confirm content for saved report
        confirmContent({ featuredContent, featureType, saved: true });
        setConfirmed(true);
      }
    }
  }, [saved, report, confirmed, featuredContent.featureId]);

  const data = {
    confirmed,
    label,
    featureType,
    options: currentOptions
  };

  const actions = {
    handleChange,
    handleConfirm,
    handleSelect
  };

  return [data, featuredContent, actions];
};

export const useReportNickname = report => {
  const savedReport = useReport();
  const [value, setValue] = useState('');
  const handleChange = event => {
    setValue(event.target.value);
  };

  useEffect(() => {
    if (report && savedReport) {
      // Set saved title as input value
      setValue(savedReport.title);
    }
  }, [report, savedReport]);

  return [value, handleChange];
};

export const usePopMsg = () => {
  const [msgEndpoint, setMsgEndpoint] = useState();
  const [msgs] = useFetch(msgEndpoint, []);

  useEffect(() => {
    setMsgEndpoint({ url: '/api/content/messages' });
  }, []);

  return [msgs];
};

export const usePopUp = () => {
  const [showModal, setModal] = useState(false);
  const [msgResp] = usePopMsg();
  const [error, setError] = useState('');
  const [msgTitle, setMsgTitle] = useState('');
  const [msgText, setMsgText] = useState('');
  const data = {
    error,
    showModal,
    msgResp,
    msgTitle,
    msgText
  };

  const actions = {
    showModal: () => {
      setError('');
      setModal(true);
    },
    hideModal: () => {
      setModal(false);
      localStorage.setItem('popup_' + data.msgTitle, 'seen');
    }
  };

  useEffect(() => {
    var name = 'popup';
    if (Object.keys(msgResp).length !== 0) {
      name = 'popup_' + msgResp.title;
    }
    const msgSeen = localStorage.getItem(name);

    if (Object.keys(msgResp).length === 0 || msgSeen !== null) {
      setModal(false);
    }
    if (Object.keys(msgResp).length !== 0 && msgSeen === null) {
      setMsgTitle(msgResp.title);
      setMsgText(msgResp.messageText);
      setModal(true);
    }
  }, [msgResp]);

  return [data, actions];
};

import { useEffect, useState } from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';
import { Footer, Navbar, Sprite, Modal, Text } from '@comcast-sir/ui-library';
import logo from '../../assets/images/logo-comcast.webp';

import {
  StatesContext,
  useAuth,
  useContentByScreen,
  useStates,
  useCities,
  usePopUp
} from '../../hooks';

import { STATE, CITY } from '../../constants';

import Dashboard from '../Dashboard/Dashboard';
import ErrorBoundary from '../ErrorBoundary/ErrorBoundary';
import ReportBuilder from '../ReportBuilder/ReportBuilder';
import ReportExport from '../ReportExport/ReportExport';
import SavedReports from '../SavedReports/SavedReports';
import KeyResources from '../KeyResources/KeyResources';
import ViewPdf from '../ViewPdf/ViewPdf';
import RequestPrintedReceipt from '../RequestPrintedReceipt/RequestPrintedReceipt';
import { gtag } from 'ga-gtag';

const Home = () => {
  const baseClass = 'sir-app-content';
  const [{ user }, authActions] = useAuth();
  const [states, onStateSelect] = useStates();
  const [cities, onCitySelect] = useCities();
  const history = useHistory();
  const content = useContentByScreen('global');

  const [investmentReportType, setInvestmentReportType] = useState(
    history?.location?.state?.city ? CITY : STATE
  );

  const toggleInvestmentReportType = () => {
    setInvestmentReportType(investmentReportType === STATE ? CITY : STATE);
  };
  const [data, actions] = usePopUp();

  useEffect(() => {
    gtag('set', { 'user_id': user.username }); // eslint-disable-line
  }, []);

  useEffect(() => {
    return history.listen(() => {
      window.scrollTo(0, 0);
    });
  }, []);

  return (
    <main className={baseClass}>
      <Sprite />
      <Navbar
        title={content.heading}
        username={user.name}
        onLogout={authActions.logout}
        logo={logo}
      />
      <StatesContext.Provider value={states}>
        <div className="sir-app-container">
          <Modal
            id="showMsgModal"
            title={data.msgTitle}
            show={data.showModal}
            onClose={actions.hideModal}
          >
            <Text element="p">
              <div
                className="sir-modal-body"
                dangerouslySetInnerHTML={{ __html: data.msgText }}
              />
            </Text>
          </Modal>
          <ErrorBoundary>
            <Switch>
              <Route path="/report" exact>
                <ReportBuilder
                  states={states}
                  cities={cities}
                  toggleInvestmentReportType={toggleInvestmentReportType}
                  investmentReportType={investmentReportType}
                />
              </Route>
              <Route path="/report/:id">
                <ReportExport />
              </Route>
              <Route path="/reports">
                <SavedReports />
              </Route>
              <Route path="/key-resources/:resourceType">
                <KeyResources />
              </Route>
              <Route path="/view-pdf">
                <ViewPdf />
              </Route>
              <Route path="/request-printed-receipt">
                <RequestPrintedReceipt />
              </Route>
              <Route path="*">
                <Dashboard
                  states={states}
                  onStateSelect={onStateSelect}
                  cities={cities}
                  onCitySelect={onCitySelect}
                  toggleInvestmentReportType={toggleInvestmentReportType}
                  investmentReportType={investmentReportType}
                />
              </Route>
            </Switch>
          </ErrorBoundary>
          <Footer copyright={content.copyright} />
        </div>
      </StatesContext.Provider>
    </main>
  );
};

export default Home;

import { bool, func, object } from 'prop-types';
import {
  SkeletonLoader,
  StatCard,
  StatCardGrid,
  Text
} from '@comcast-sir/ui-library';

const StatCategory = props => {
  const { category, disabled, loaded, onSelect } = props;

  return (
    <SkeletonLoader height="50px" loaded={loaded}>
      <StatCardGrid category={category.name} subheading={category?.subheading}>
        {category.stats.map(stat => {
          const caption = stat.caption.split('\n');

          return (
            <StatCard
              key={stat.id}
              {...stat}
              label={stat.type}
              checked={stat.order !== ''}
              disabled={disabled && !stat.order}
              onSelect={onSelect}
            >
              {caption.map((line, index) =>
                line ? (
                  <Text key={`${stat.id}-${index}`} element="p" size="xsmall">
                    {line}
                  </Text>
                ) : (
                  <p key={index} className="sir-stat-caption--empty" />
                )
              )}
            </StatCard>
          );
        })}
      </StatCardGrid>
    </SkeletonLoader>
  );
};

StatCategory.propTypes = {
  category: object,
  disabled: bool,
  loaded: bool,
  onSelect: func.isRequired
};

export default StatCategory;

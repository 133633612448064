import { Button, Card, Heading, Text } from '@comcast-sir/ui-library';
import logo from '../../assets/images/logo-comcast.png';

import { useAuth, useContentByScreen } from '../../hooks';
import { replaceLinks } from '../../helpers';

const Login = () => {
  const baseClass = 'sir-login';
  const contentClass = `${baseClass}-content`;
  const [, actions] = useAuth();
  const content = useContentByScreen('pre-login');
  const heading =
    content.heading ||
    'Welcome to the \nState Investment Reports Digital Portal';

  return (
    <div className={baseClass}>
      <div className="sir-app-container">
        <Card withShadow>
          <img
            className={`${baseClass}-logo`}
            alt="Comcast NBCUniversal logo"
            src={logo}
          />
          <div className={contentClass}>
            <Heading element="h1" withLinebreak>
              {heading}
            </Heading>
            <div className={`${contentClass}-body`}>
              <Text element="p">{content.description}</Text>
              <Button modifier="primary" size="large" onClick={actions.login}>
                Log in with SSO
              </Button>
              <Text element="p" size="small">
                {replaceLinks(content.help)}
              </Text>
              <Text element="p" size="tiny">
                {content['opt-in']}
              </Text>
            </div>
          </div>
        </Card>
      </div>
    </div>
  );
};

export default Login;
